<template>
  <v-card class="view-wrapper">
    <v-card-title class="justify-center pt-3 pb-1"><h3>{{ $localize('fill_the_form') }}</h3></v-card-title>
    <v-card-text class="overflow-auto input-number-without-spin-button pb-2" style="height: calc(100vh - 114px);">
      <v-container class="width-adaptive-container">
        <v-text-field v-model="item.author.lastName"
                      :rules="[rules.required]"
                      :label= "$localize('last_name')"
                      outlined
                      clearable
                      dense
        ></v-text-field>
        <v-text-field v-model="item.author.firstName"
                      :rules="[rules.required]"
                      :label= "$localize('first_name')"
                      outlined
                      clearable
                      dense
        ></v-text-field>
        <v-text-field v-model="item.author.middleName"
                      :rules="[rules.required]"
                      :label= "$localize('middle_name')"
                      outlined
                      clearable
                      dense
        ></v-text-field>
        <v-text-field v-model="item.author.phone"
                      :rules="[rules.required]"
                      :label= "$localize('telephone_number')"
                      prepend-inner-icon="mdi-phone"
                      return-masked-value
                      v-mask="'+7(###)-###-##-##'"
                      outlined
                      clearable
                      dense
        ></v-text-field>
        <v-text-field v-model="item.author.email"
                      :rules="[rules.required, rules.email]"
                      :label= "$localize('email')"
                      outlined
                      clearable
                      dense
        ></v-text-field>
        <v-file-input v-model="item.author.photo"
                      accept="image/png, image/jpeg"
                      :label="$localize('photo')"
                      :prepend-icon="null"
                      prepend-inner-icon="mdi-account"
                      outlined
                      clearable
                      dense
        ></v-file-input>
        <v-text-field v-model="item.city"
                      :rules="[rules.required]"
                      :label= "$localize('city_of_residence')"
                      outlined
                      clearable
                      dense
        ></v-text-field>
        <v-autocomplete v-model="item.activities"
                        :items="activityTypes"
                        :rules="[rules.required]"
                        item-text="title"
                        :label= "$localize('field_of_activity')"
                        outlined
                        clearable
                        dense
        ></v-autocomplete>
        <v-autocomplete v-model="item.planAmount"
                        :items="planAmountItems"
                        :rules="[rules.required]"
                        item-text="title"
                        :label= "$localize('planned_amount_of_your_investment_in_one_project')"
                        outlined
                        clearable
                        dense
        ></v-autocomplete>
        <v-autocomplete v-model="item.investmentPurposes"
                        :items="investmentPurposesItems"
                        :rules="[rules.required]"
                        item-text="title"
                        :label= "$localize('investment_purpose')"
                        outlined
                        clearable
                        dense
        ></v-autocomplete>
        <v-text-field v-if="item.investmentPurposes && item.investmentPurposes['key'] === 'OTHER'"
                      v-model="item.other"
                      :rules="[rules.required]"
                      :label= "$localize('other')"
                      outlined
                      clearable
                      dense
        ></v-text-field>
        <v-autocomplete v-model="item.term"
                        :items="termItems"
                        :rules="[rules.required]"
                        item-text="title"
                        :label= "$localize('planned_investment_period')"
                        outlined
                        clearable
                        dense
        ></v-autocomplete>
        <v-text-field v-model="item.decision"
                      :rules="[rules.required]"
                      :label= "$localize('why_did_you_decide_to_invest_in_ABC_deals?')"
                      outlined
                      clearable
                      dense
        ></v-text-field>
        <v-row class="justify-center pa-0 ma-0">
          <v-btn
              color="success"
              :outlined="!isFormFilled"
              class="ma-0 pa-0"
              @click="save"
          >
            <span class="ml-8 mr-8">{{$localize('save')}}</span>
          </v-btn>
        </v-row>
      </v-container>
    </v-card-text>
  </v-card>
</template>

<script>
const emailRegex = new RegExp(/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/);

export default{
  name: "AdminQuestionnaire",
  data : () => ({
    item: {
      author: {
        lastName: undefined,
        firstName: undefined,
        middleName: undefined,
        phone: undefined,
        email: undefined,
        photo: undefined,
      },
      city: undefined,
      // activities: [],
      activities: undefined,
      planAmount: undefined,
      // // investmentPurposes: [],
      investmentPurposes: undefined,
      other: undefined,
      term : undefined,
      decision: undefined,
    },
    activityTypes: [],
    planAmountItems:[
      {key : 'P100', title: 'до 100 000 тенге'},
      {key : 'P500', title: 'от 500 000 до 5 000 000 тенге'},
      {key : 'P5000', title: 'от 5 000 000 до 10 000 000 тенге'},
      {key : 'P10000', title: 'от 10 000 000 до 100 000 000 тенге'},
      {key : 'P100000', title: 'свыше 100 000 000 тенге'},
    ],
    investmentPurposesItems: [
      {key : 'PROCESS', title: 'Для меня важен сам процесс инвестирования – я не рассчитываю в ближайшем времени использовать инвестированные средства'},
      {key : 'RISE', title: 'Я рассчитываю на существенный рост стоимости вкладываемых мной инвестиций'},
      {key : 'SAVE_ACCUMULATED', title: 'Мне важно сохранить накопленные средства от обесценения с доходностью чуть выше инфляции'},
      {key : 'OTHER', title: 'Другое (расшифруйте в поле ниже)'},
    ],
    dictionaries: [],
    termItems: [
      {key : 'SHORT', title: 'SHORT'},
      {key : 'LONG', title: 'LONG'},
    ],
    rules: {
      required: value => !!value || 'Обязательное поле',
      email: value => {
        return emailRegex.test(value) || 'Введите эл. почту'
      },
    }
  }),

  computed:{
    isFormFilled(){
      for (let key in this.item){
        if(!this.item[key] && key !== 'other') return false
      }
      for(let authorKey in this.item.author){
        if(!this.item.author[authorKey] && authorKey) return false
        else {
          switch (authorKey){
            case 'phone' : if(this.item.author[authorKey].length < 17) return false; break
            case 'email' : if(!emailRegex.test(this.item.author[authorKey])) return false; break
          }
        }
      }
      return true
    }
  },

  mounted(){
    this.initDict()
    this.initActivityTypesDict()
  },

  methods:{
    async initDict(){
      const res = await this.$fetch.get('api/dictionary/get-list')
      if(res) this.dictionaries = res.filter(el => {return el.type ==='ACTIVITY'})
    },

    async initActivityTypesDict(){
      const res = await this.$fetch.get('api/dictionary/get-by-type-and-parent?type=ACTIVITY&parentUid=')
      if(res) this.activityTypes = res
    },

    save(){
      if(this.isFormFilled){
        this.$store.dispatch('setError',{ show: true, type: 'success', message: 'Успешно!' })
      }else this.$store.dispatch('setError',{ show: true, type: 'warning', message: 'Заполните поля верно' })
    }
  }
}
</script>
